<template>
  <div id="topiclocal1">
    <div class="title">
      <div v-if="this.$route.query.filetype == '8'" class="left">
        *{{ fileName }}资源上传
      </div>
      <div v-else class="left">*问卷资源上传</div>
      <div class="right">
        <router-link
          :to="{
            path: '/main/addscene/topicresource/topiclocal/topiclocal2',
            query: {
              csid: this.$route.query.csid,
              index: '',
              dataid: this.$route.query.dataid,
              filetype: this.$route.query.filetype,
            },
          }"
          >手动添加{{ fileName }}</router-link
        >
        <router-link
          :to="{
            path: '/main/addscene/topicresource/topiclocal/topiclocal3',
            query: {
              csid: this.$route.query.csid,
              index: '',
              dataid: this.$route.query.dataid,
              filetype: this.$route.query.filetype,
            },
          }"
          >快捷导入{{ fileName }}</router-link
        >
        <a
          v-show="topicLength != 0"
          @click="topicout()"
          to=""
          style="cursor: pointer"
          >导入{{ fileName }}（{{ topicLength }}）</a
        >
      </div>
    </div>
    <!-- <table v-if="this.$route.query.muban == 1 && !this.$route.query.bj">
      <tr>
        <th>序号</th>
        <th>{{ fileName }}类型</th>
        <th>{{ fileName }}题干</th>
        <th>答案A</th>
        <th>答案B</th>
        <th>答案C</th>
        <th>答案D</th>
        <th>正确答案</th>
        <th>{{ fileName }}解析</th>
        <th>操作</th>
      </tr>
      <tr v-for="(item, index) in mubanList" :key="index">
        <td>{{ index + 1 }}</td>
        <td>{{ item.type == 0 ? "选择题" : "判断题" }}</td>
        <td>{{ item.question }}</td>
        <td>{{ item.a }}</td>
        <td>{{ item.b }}</td>
        <td>{{ item.c }}</td>
        <td>{{ item.d }}</td>

        <td>
          <span>{{ item.answer }}</span>
        </td>
        <td>
          <span>{{ item.analysis }}</span>
        </td>
        <td>
          <span @click="change(index)">编辑</span>
          <span @click="delFn(index)">删除</span>
        </td>
      </tr>
    </table>
    <table
      v-else-if="this.$route.query.muban == 1 && this.$route.query.bj == 1"
    >
      <tr>
        <th>序号</th>
        <th>{{ fileName }}类型</th>
        <th>{{ fileName }}题干</th>
        <th>答案A</th>
        <th>答案B</th>
        <th>答案C</th>
        <th>答案D</th>
        <th>正确答案</th>
        <th>{{ fileName }}解析</th>
        <th>操作</th>
      </tr>
      <tr v-for="(item, index) in mubanList" :key="index">
        <td>{{ index + 1 }}</td>
        <td>{{ item.type }}</td>
        <td v-html="item.question"></td>
        <td v-html="item.a"></td>
        <td v-html="item.b"></td>
        <td v-html="item.c"></td>
        <td v-html="item.d"></td>

        <td>
          <span v-html="item.answer"></span>
        </td>
        <td>
          <span v-html="item.analysis"></span>
        </td>
        <td>
          <span @click="change(index)">编辑</span>
          <span @click="delFn(index)">删除</span>
        </td>
      </tr>
    </table> -->
    <table v-if="fileName == 8 && !this.$route.query.muban">
      <tr>
        <th>序号</th>
        <th>{{ fileName }}类型</th>
        <th>{{ fileName }}题干</th>
        <th>答案A</th>
        <th>答案B</th>
        <th>答案C</th>
        <th>答案D</th>
        <th>正确答案</th>
        <th>{{ fileName }}解析</th>
        <th>操作</th>
      </tr>
      <tr v-for="(item, index) in topicList" :key="index">
        <td>{{ index + 1 }}</td>
        <td>{{ item.type }}</td>
        <td v-html="item.timu"></td>
        <td v-html="item.choseA"></td>
        <td v-html="item.choseB"></td>
        <td v-html="item.choseC"></td>
        <td v-html="item.choseD"></td>

        <td>
          <span v-html="item.option"></span>
        </td>
        <td>
          <span v-html="item.desecript"></span>
        </td>
        <td>
          <span @click="change(index)">编辑</span>
          <span @click="delFn(index)">删除</span>
        </td>
      </tr>
    </table>
    <table v-else>
      <tr>
        <th>序号</th>
        <th>{{ fileName }}类型</th>
        <th>{{ fileName }}题干</th>
        <th>答案A</th>
        <th>答案B</th>
        <th>答案C</th>
        <th>答案D</th>
        <!-- <th>正确答案</th>
        <th>{{ fileName }}解析</th> -->
        <th>操作</th>
      </tr>
      <tr v-for="(item, index) in topicList" :key="index">
        <td>{{ index + 1 }}</td>

        <!-- <td v-if="item.type == Number()">
          {{ item.type == 0 ? "选择题" : "判断题" }}
        </td>
        <td v-else>{{ item.type }}</td> -->
        <td>{{ item.type }}</td>
        <td v-html="item.timu"></td>
        <td v-html="item.choseA"></td>
        <td v-html="item.choseB"></td>
        <td v-html="item.choseC"></td>
        <td v-html="item.choseD"></td>

        <!-- <td>
          <span v-html="item.option"></span>
        </td>
        <td>
          <span v-html="item.desecript"></span>
        </td> -->
        <td>
          <span @click="change(index)">编辑</span>
          <span @click="delFn(index)">删除</span>
        </td>
      </tr>
    </table>
    <!-- <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="7"
        :page-size="7"
        layout="total, sizes, prev, pager, next, jumper"
        :total="topicList"
      >
      </el-pagination>
    </div> -->
  </div>
</template>
<script>
import * as util from "@/unit/network";
export default {
  data() {
    return {
      topicList: "",
      topicLength: 0,
      list: "",
      currentPage: 1,
      fileName: "",

      // mubanList: "",
    };
  },
  mounted() {
    this.getinfo();
    if (this.$route.query.filetype == "6") {
      this.fileName = "问卷";
    } else {
      this.fileName = "试题";
    }
    // localStorage.setItem("mubanlist", []);
    // 编辑进入
    if (this.$route.query.edit == 1) {
      util.post(
        "/questionList",
        {
          catalog_file_id: this.$route.query.dataid,
        },
        (res) => {
          console.log(res);
          this.topicList = res.data;
          localStorage.setItem(
            "jsonkey" + this.$route.query.dataid,
            JSON.stringify(res.data)
          );
        }
      );
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    getinfo() {
      let jsonstr = window.localStorage.getItem(
        "jsonkey" + this.$route.query.dataid
      );
      let json = JSON.parse(jsonstr);
      this.topicList = json;
      if (json == null) {
        this.topicLength = 0;
      } else {
        this.topicLength = json.length;
      }

      // if (this.$route.query.muban == 1) {
      //   let arr = localStorage.getItem("mubanlist");
      //   this.mubanList = JSON.parse(arr);
      //   this.topicLength = this.mubanList.length;
      //   console.log(this.mubanList);
      // }
    },
    topicout() {
      // if (this.$route.query.muban == 1) {
      //   util.post(
      //     "/upload_question",
      //     {
      //       questionArr: this.mubanList,
      //       id: this.$route.query.dataid,
      //     },
      //     (res) => {
      //       console.log(res);
      //       localStorage.removeItem("mubnalist");
      //       this.mubanList = "";
      //       this.topicLength = "";
      //       this.$router.push({
      //         path: "/main/addscene",
      //         query: { csid: this.$route.query.csid },
      //       });
      //     }
      //   );
      // } else if (!this.$route.query.muban) {
      var ques = "";
      if (this.$route.query.filetype == 8) {
        ques = "/upload_question";
      } else {
        ques = "/upload_questionnaire";
      }
      if (this.$route.query.edit == 1) {
        util.post(
          ques,
          {
            questionArr: this.topicList,
            id: this.$route.query.dataid,
          },
          (res) => {
            console.log(res);
            localStorage.removeItem("jsonstr");
            this.topicList = "";
            this.topicLength = 0;
            this.$router.push({
              path: "/main/addscene",
              query: { csid: this.$route.query.csid },
            });
          }
        );
      } else if (!this.$route.query.edit) {
        util.post(
          ques,
          {
            questionArr: this.topicList,
            id: this.$route.query.dataid,
          },
          (res) => {
            console.log(res);
            localStorage.removeItem("jsonstr");
            this.topicList = "";
            this.topicLength = 0;
            this.$router.push({
              path: "/main/addscene",
              query: { csid: this.$route.query.csid },
            });
          }
        );
      }

      // }
    },
    delFn(i) {
      this.list = JSON.parse(
        localStorage.getItem("jsonkey" + this.$route.query.dataid) || "[]"
      );
      this.list.splice(i, 1);
      localStorage.setItem(
        "jsonkey" + this.$route.query.dataid,
        JSON.stringify(this.list)
      );
      this.getinfo();
    },
    // 编辑
    change(i) {
      // if (this.$route.query.muban == 1) {
      //   this.$router.push({
      //     path: "/main/addscene/topicresource/topiclocal/topiclocal2",
      //     query: {
      //       csid: this.$route.query.csid,
      //       index: i,
      //       dataid: this.$route.query.dataid,
      //       muban: 1,
      //       filetype: 8,
      //     },
      //   });
      // } else {
      this.$router.push({
        path: "/main/addscene/topicresource/topiclocal/topiclocal2",
        query: {
          csid: this.$route.query.csid,
          index: i,
          dataid: this.$route.query.dataid,
          filetype: this.$route.query.filetype,
          changes: 1,
        },
      });
      // }
    },
  },
};
</script>
<style lang="less" scoped>
#topiclocal1 {
  // height: 100%;
  background-color: #fff;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36/96in;
    .left {
      font-weight: bold;
    }
    .right {
      a {
        width: 120/96in;
        color: #fff;
        margin-left: 11px;
        background-color: #1c5eff;
        border-radius: 5px;
        padding: 11/96in 19/96in;
        font-size: 14/96in;
      }
    }
  }
  table {
    margin-top: 20px;
    width: 100%;
    border-collapse: collapse;

    border: 1px solid #d7d7d7;
    th {
      height: 59/96in;
      border-collapse: collapse;
      border: 1px solid #d7d7d7;
    }
    td {
      height: 96px;
      text-align: center;
      border-collapse: collapse;
      border: 1px solid #d7d7d7;
      max-width: 230px;
      overflow: hidden;
      /deep/img {
        width: 200px;
      }
    }

    td:last-child {
      span {
        cursor: pointer;
        margin: 0 3px;
      }
    }
  }
}
</style>